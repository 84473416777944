exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-data-sources-js": () => import("./../../../src/pages/data-sources.js" /* webpackChunkName: "component---src-pages-data-sources-js" */),
  "component---src-pages-goal-tracking-js": () => import("./../../../src/pages/goal-tracking.js" /* webpackChunkName: "component---src-pages-goal-tracking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-okr-js": () => import("./../../../src/pages/okr.js" /* webpackChunkName: "component---src-pages-okr-js" */),
  "component---src-pages-podcasters-js": () => import("./../../../src/pages/podcasters.js" /* webpackChunkName: "component---src-pages-podcasters-js" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-tasks-management-js": () => import("./../../../src/pages/tasks-management.js" /* webpackChunkName: "component---src-pages-tasks-management-js" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

